import { Button } from '..'
import React from 'react'
import './style.css'

const ContactUs = () => {
    return (
        <div className='contact-us-root'>
            <div className='contact-us container'>
                <div className='product'>
                    <div className='call-text'>Ready to talk? Contact us at anytime.</div>
                    <span className='product-head'>Products</span>
                    <span className='product-name'>NetBackup Appliance Solutions</span>
                    <span className='product-name'>NetBackup IT Analytics</span>
                    <span className='product-name'>Merge1</span>
                </div>
                <div className='call'>
                    <div className='call-number'>
                        <Button text="Request a call" />
                        <span>1 (866) 837-4827</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs