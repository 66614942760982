/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import './style.css'
import '../../components'
import { Companies, ContactUs, FirstSection, ProductSection } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import { userUpdate } from '../../redux/UserSlice'
import { useEffect } from 'react'
import { isLoggedIn } from '../../assets/helper'

const Home = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth?.userData)
    const reloadUserInfo = useCallback(
        async () => {
            const tokenData = await Auth.currentSession()
            const userData = await Auth.currentUserInfo();
            const authData = {
                token: tokenData.idToken.jwtToken,
                ...userData.attributes,
                username: userData.username,
                groups: tokenData.accessToken?.payload["cognito:groups"],
                accessJwtToken: tokenData.accessToken?.jwtToken,
            };
            dispatch(userUpdate(authData))
        },
        [dispatch],
    )

    useEffect(() => {
        if (isLoggedIn() && !user) reloadUserInfo()
    }, [reloadUserInfo])
    return (
        <>
            <FirstSection />
            <ProductSection />
            <Companies />
            <ContactUs />
        </>
    )
}

export default Home