import React from 'react'
import './style.css'
const SelectComponent = ({ onChange, items, value, name, label }) => {
    return (
        <div className="select-component-form-control">
            <select className="select-component-form-input" name={name} value={value} onChange={onChange}>
                <option disabled hidden>-- Please Select --</option>
                {items.map(item => <option key={item} value={item}>{item}</option>)}
            </select>
            <label className='select-component-form-label'>{label}</label>
        </div>
    )
}

export default SelectComponent