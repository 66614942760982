/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import './style.css';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/UserSlice';
import { Auth } from 'aws-amplify';

const RegistrationCard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const INITIAL_FORM = useMemo(() => ({ email: "", password: "" }), [])
    const [form, setForm] = useState(INITIAL_FORM)
    const [showPass, setShowPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loginerror, setLoginerror] = useState("")
    const [showerror, setShowerror] = useState(false)


    // const reloadUserInfo = useCallback(
    //     async () => {
    //         const tokenData = await Auth.currentSession()
    //         const userData = await Auth.currentUserInfo();
    //         const authData = {
    //             token: tokenData.idToken.jwtToken,
    //             ...userData.attributes,
    //             username: userData.username,
    //             groups: tokenData.accessToken?.payload["cognito:groups"],
    //             accessJwtToken: tokenData.accessToken?.jwtToken,
    //         };
    //         dispatch(userUpdate(authData))
    //     },
    //     [dispatch],
    // )

    const handleFormChange = useCallback(
        (e) => {
            setShowerror(false)
            const { name, value } = e.target;
            setForm(state => ({ ...state, [`${name}`]: value }))
        },
        [],
    )
    const handleLogin = useCallback(
        async () => {
            setLoading(true)
            const { email, password } = form
            try {
                const response = await Auth.signIn({ username: email, password })
                const tokenData = await Auth.currentSession()
                const userData = await Auth.currentUserInfo();
                console.log(userData)
                const authData = {
                    token: tokenData.idToken.jwtToken,
                    ...userData.attributes,
                    username: userData.username,
                    groups: tokenData.accessToken?.payload["cognito:groups"],
                    accessJwtToken: tokenData.accessToken?.jwtToken,
                };
                dispatch(login({ data: authData, token: response.signInUserSession.idToken.jwtToken }))
                console.log("response", response)
                navigate('/')
                setLoading(false)
            }
            catch (error) {
                console.log(error.message)
                setLoading(false)
                setLoginerror(error.message)
                setShowerror(true)
            }
        },
        [form],
    )

    return (
        <div className='login-card-container'>

            <div className="reg-title text-center">Log In</div>
            {showerror ? (<div className="Error-Message"> {loginerror}</div>) : <></>}
            <div className="input-fields">
                <Row>
                    <Col md={12} lg={12}>
                        <div className='login-form'>
                            <div className="form-control">
                                <input type="email" className="form-input" placeholder="email" name='email' value={form.email} onChange={handleFormChange} />
                                <label className='form-label'>Email</label>
                            </div>
                            <div className="form-control">
                                <input type={showPass ? "text" : "password"} className="form-input" placeholder="password" name='password' value={form.password} onChange={handleFormChange} />
                                <label className='form-label'>Password</label>
                                <div className='inputIcon' onClick={() => setShowPass(state => !state)}>
                                    {showPass ? <BsEyeSlash /> : <BsEye />}
                                </div>
                            </div>
                            <div className="form-label-forgotpassword" onClick={() => navigate('/forgortpassword')}>Forgot Password</div>
                            <Button text={loading ? <Spinner /> : "Log In"} onClick={handleLogin} />
                            <span className='login-text'>Don't have an account? <span className='login-link' onClick={() => navigate('/registration', { replace: true })}>Create one!</span></span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default RegistrationCard