import React from 'react'
import './style.css'
import { dpi1 } from '../../assets/images'
import { useNavigate } from 'react-router-dom'
import { isLoggedIn } from '../../assets/helper'

const ProductCard = ({ logo, title, description, id }) => {
    const navigate = useNavigate()
    return (
        <div className='product-card'>
            <div className='product-container'>
                <div className='product-inner'>
                    <img src={dpi1} alt="product icon" />
                    <div className='product-card-image'></div>
                </div>
                <div className='product-card-title'>{title}</div>
                <div className='product-card-content'>{description}</div>
            </div>
            <div className="product-info-container" onClick={
                () => {
                    if (!isLoggedIn()) { navigate('/login'); return }
                    else {
                        navigate('/products/' + id)
                    }
                }
            }>
                <span className='product-info'>View product info</span>
            </div>
        </div >
    )
}

export default ProductCard