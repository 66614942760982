import React, { useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
    cerner, fedex, intel, merkle, nationwide, rent, steelcase, teradata, tmobile, united,
} from '../../assets/images'
import './style.css'
const Companies = () => {
    const CompanyList = useMemo(() => ([cerner, fedex, intel, merkle, nationwide, rent, steelcase, teradata, tmobile, united,]), [])
    return (
        <div className='companies container'>
            <div className='company-tagline container'>
                Trusted by many of the world's largest companies, including 95% of the Fortune 100.
            </div>
            <div className='company-list'>
                <Row>{CompanyList.map((company, id) => <Col key={id} md={3} className='company'><img src={company} alt="company" /></Col>)}</Row>
            </div>
        </div>
    )
}

export default Companies