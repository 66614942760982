import { createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {}
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            localStorage.setItem('token', action.payload.token)
            return { ...state, userData: action.payload.data }
        },
        logout: async (state) => {
            localStorage.removeItem('token')
            return initialState
        },
        userUpdate: (state, action) => {
            localStorage.setItem('token', action.payload.token)
            return { ...state, userData: action.payload }
        },
    }
})

export const { login, logout, userUpdate } = userSlice.actions

export default userSlice.reducer