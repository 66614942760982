import React, { useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getProducts } from '../../api'
import { psg1, psg2 } from '../../assets/images'
import { ProductCard } from '../index'
import './style.css'
import Swal from 'sweetalert2'


const ProductSection = () => {
    const { error, isLoading, data = [] } = useQuery('getProducts', { queryFn: () => getProducts() })
    console.log("data", data)
    useEffect(() => {
        error && Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
        })
    }, [error])
    return (
        <>
            <div className='product-section' id='product-section'>
                <div className='product-section-heading'>
                    <img src={psg1} alt="Product section graphics 1" />
                    <span>
                        <div>Products</div>
                        <div>Our Products</div>
                    </span>
                </div>
                <img src={psg2} alt="Product section graphics 1" />
            </div>
            <Row className='product-list container'>
                {
                    isLoading ? <div className='loader-container'><Spinner /></div> : data ? data?.data?.map((product, id) =>
                        <Col md={6} lg={4} xl={4} key={id} className="mt-1 mb-1"><ProductCard title={product.name} description={product.description} id={product.uuid} /></Col>
                    ) : <></>}
            </Row>
        </>
    )
}

export default ProductSection