import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import FormSlice from './redux/FormSlice';
import skuSlice from './redux/SkuSlice';
import userSlice from './redux/UserSlice'

export default configureStore({
    reducer: { user: userSlice, form: FormSlice, sku: skuSlice },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, thunk),
    devTools: process.env.NODE_ENV !== 'production',
})