import { createBrowserRouter } from 'react-router-dom'

import {
    ContactInfo,
    FinalConfirmation,
    Form, Home, Layout, Login, Orders, Products, QuoteConfirmation, Registration, Secure, SelectSku, TrialQuestionnaire
} from './pages'
import ForgotPassword from './pages/ForgotPassword'

export const router = createBrowserRouter([
    {
        path: '/', element: <Layout />, children: [
            { index: true, element: <Home /> },
            { path: '/login', element: <Login /> },
            { path: '/forgortpassword', element: <ForgotPassword /> },
            { path: '/registration', element: <Registration /> },
            {
                path: '/products', element: <Secure />, children: [
                    { path: '/products/:id', element: <Products /> },
                    {
                        path: '/products/form', element: <Form />, children: [
                            { path: '/products/form/trial-questionnaire/:id', element: <TrialQuestionnaire /> },
                            { path: '/products/form/select-sku/:id', element: <SelectSku /> },
                            { path: '/products/form/quote-confirmation', element: <QuoteConfirmation /> },
                            { path: '/products/form/contact-info', element: <ContactInfo /> },
                            { path: '/products/form/final-confirmation', element: <FinalConfirmation /> }
                        ]
                    },
                ]
            }
        ]
    },
    {
        path: '/orders', element: <Orders />
    }
])