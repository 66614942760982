import { Logo, yt, insta, linkedin, twitter } from '../../assets/images'
import React from 'react'
import './style.css'

const Footer = () => {
    return (
        <div className='footer container'>
            <div className='footer-logo'>
                <img src={Logo} alt="logo" />
            </div>
            <div className='footer-links'>
                <span>© 2022 Veritas</span>
                <span>Privacy</span>
                <span>Legal</span>
                <span>Cookies</span>
            </div>
            <div className='footer-social'>
                <span><img src={twitter} alt="twitter" /></span>
                <span><img src={yt} alt="yt" /></span>
                <span><img src={insta} alt="insta" /></span>
                <span><img src={linkedin} alt="linkedin" /></span>
            </div>
        </div>
    )
}

export default Footer