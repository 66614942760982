import React, { useMemo } from 'react';
import './style.css';
import { Col, Row, Spinner } from 'react-bootstrap';
import Button from '../Button';
import { useState } from 'react';
import { useCallback } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const ForgotPasswordCard = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [mailsent, setMailsent] = useState(true);
    const INITIAL_FORM = useMemo(() => ({ email: "", password: "", new_password: "", code: "" }), [])
    const [form, setForm] = useState(INITIAL_FORM)
    const handleFormChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            setForm(state => ({ ...state, [`${name}`]: value }))
        },
        [],
    )

    const sendcode = useCallback(async () => {
        const { email } = form
        setLoading(true)
        Auth.forgotPassword(email)
            .then(data => console.log(data), setLoading(false), setMailsent(false))
            .catch(err => console.log(err), setLoading(false))

    }, [form])

    const submit = useCallback(async () => {
        const { email, code, new_password } = form
        Auth.forgotPasswordSubmit(email, code, new_password)
            .then(data => {
                console.log(data);
                Swal.fire({
                    icon: 'success',
                    title: 'Password Reset Successful',
                }); navigate('/login')
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                }); navigate('/login')
            });

    }, [form])

    return <> {mailsent ?
        <div className='forgortpassword-card-container'>
            <div className="reg-title text-center">Please Enter your Email</div>
            <div className="input-fields">
                <Row>
                    <Col md={12} lg={12}>
                        <div className='login-form'>
                            <div className="form-control">
                                <input type="email" className="form-input" placeholder="email" name='email' value={form.email} onChange={handleFormChange} />
                                <label className='form-label'>Email</label>
                            </div>
                            <Button text={loading ? <Spinner /> : "Send Code"} onClick={sendcode} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        : <div className='forgortpassword-card-container'>
            <div className="reg-title text-center">Please Enter The Verification Code</div>
            <div className="input-fields">
                <Row>
                    <Col md={12} lg={12}>
                        <div className='login-form'>
                            <div className="form-control">
                                <input type="text" className="form-input" placeholder="Verification Code" name='code' value={form.code} onChange={handleFormChange} />
                                <label className='form-label'>Verification Code</label>
                            </div>
                            <div className="form-control">
                                <input type="text" className="form-input" placeholder="new_password" name='new_password' value={form.new_password} onChange={handleFormChange} />
                                <label className='form-label'>New Password</label>
                            </div>
                            <Button text={loading ? <Spinner /> : "Submit"} onClick={submit} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    }

    </>
}
export default ForgotPasswordCard



