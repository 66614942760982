import React, { useCallback, useMemo } from 'react'
import './style.css'
import { useTable, usePagination, useExpanded } from 'react-table'
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi'
import QuoteConfirmation from '../../pages/QuoteConfirmation';
import { Spinner } from 'react-bootstrap';
import { FORM_STATUS } from '../../assets/helper';
import moment from 'moment-timezone'
const DOTS = <span className='paginationBtn'>&#8230;</span>;
const BootstrapTable = ({ data,
    fetchData,
    loading,
    pageCount: controlledPageCount
}) => {

    const columns = React.useMemo(
        () => [
            {
                Header: <div className='order-id-column'>Order Id</div>,
                id: 'expander',
                accessor: 'OrderId', // accessor is the "key" in the data
                Cell: (value) => {
                    return <div className='order-id' {...value.row.getToggleRowExpandedProps()}>{value.cell.value}</div>
                }
            },
            {
                Header: <div className='common-header'>Customer Name</div>,
                accessor: 'customerName',
                Cell: (value) => <div className='customer-name'>{value.cell.value}</div>
            },
            {
                Header: <div className='common-header'>Company Name</div>,
                accessor: 'companyName',
                Cell: (value) => <div className='customer-name'>{value.cell.value}</div>
            },
            {
                Header: <div className='common-header'>Order Date</div>,
                accessor: 'createTime',
                Cell: (value) => <div className='customer-name'>{value.cell.value ? moment(value.cell.value?.split('T')[0]).format('MMM Do, YY') : ""}</div>
            },
            {
                Header: <div className='common-header'>Product SKU</div>,
                accessor: 'sku',
                Cell: (value) => <div className='product'>{value.cell.value}</div>
            },
            {
                Header: () => <div className='status-column'>Current Status</div>,
                accessor: 'status',
                Cell: (value) => {
                    if (value.cell.value === FORM_STATUS.submitted) return <div className='status-submitted'>{value.cell.value}</div>
                    if (value.cell.value === FORM_STATUS.quote_requested) return <div className='status-customization'>{value.cell.value}</div>
                    if (value.cell.value === FORM_STATUS.awaiting_signature) return <div className='status-provisioning'>{value.cell.value}</div>
                    if (value.cell.value === FORM_STATUS.provisioning_in_progress) return <div className='status-provisioning'>{value.cell.value}</div>
                    if (value.cell.value === FORM_STATUS.provisioned) return <div className='status-provisioning'>{value.cell.value}</div>
                    return <div className='form'>{value.cell.value}</div>
                }
            },
        ],
        []
    )

    const siblingCount = useMemo(() => 2, [])

    const range = useCallback((start, end) => {
        let length = end - start + 1;
        /*
              Create an array of certain length and set the elements within it from
          start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: { pageIndex, pageSize },
    } = useTable({
        columns, data, initialState: { pageIndex: 0 },
        manualPagination: true,
        pageCount: controlledPageCount,
    }, useExpanded, usePagination)

    const leftSiblingIndex = Math.max(pageIndex - siblingCount, 1);
    const rightSiblingIndex = Math.min(pageIndex + siblingCount, controlledPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < controlledPageCount - 2;

    let middlePageIndex = [];

    if (!shouldShowLeftDots && shouldShowRightDots) {
        middlePageIndex = range(2, rightSiblingIndex);
    }
    if (!shouldShowRightDots && shouldShowLeftDots) {
        middlePageIndex = range(leftSiblingIndex, controlledPageCount - 1);
    }
    if (shouldShowLeftDots && shouldShowRightDots) {
        middlePageIndex = range(leftSiblingIndex, rightSiblingIndex);
    }
    if (!shouldShowLeftDots && !shouldShowRightDots) {
        middlePageIndex = range(2, controlledPageCount - 1);
    }

    React.useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    // console.log({
    //     canPreviousPage,
    //     canNextPage,
    //     pageOptions,
    //     pageCount,
    //     gotoPage,
    //     nextPage,
    //     previousPage,
    //     setPageSize,
    //     pageIndex,
    //     pageSize,
    //     controlledPageCount
    // });


    const renderRowSubComponent = React.useCallback(
        ({ row }) => {
            return <QuoteConfirmation id={row.original.OrderId} fetch={fetchData} />
        },
        []
    )
    console.log("data", data)
    if (loading) return <div className='loader-container'><Spinner /></div>

    return (
        <div className='bootstrap-table-root'>
            {/* <div className='first-sec'>
                <div>Showing 1 - 10 of 64</div>
                <div>Results per page: <select value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}>{[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}</select></div>
            </div> */}
            <div className='second-sec'>
                <table {...getTableProps()} className='bootstrap-table'>
                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='bootstrap-table-head'>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <>
                                    <tr {...row.getRowProps()} className='bootstrap-table-data'>
                                        <>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={cell.column.id === 'status' ? 'status-cell' : ''}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </>
                                    </tr>
                                    {
                                        row.isExpanded ? (
                                            <tr>
                                                <td colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })}
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                </>
                            )
                        })}
                    </tbody>
                </table>
                {/* pagination */}
                {/* <div className="pagination">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className='paginationBtn'>
                        <HiOutlineArrowLeft />
                    </button>{' '}
                    <button onClick={() => gotoPage(1)} disabled={!canPreviousPage} className='paginationBtn'>
                        {'First'}
                    </button>{' '}
                    <button
                        onClick={() => gotoPage(1)}
                        key={1}
                        className={`paginationBtn ${pageIndex === 1 ? "currPage" : ""}`}
                    >
                        1
                    </button>
                    {shouldShowLeftDots && DOTS}
                    {middlePageIndex.map((num) => (
                        <button
                            onClick={() => gotoPage(num)}
                            key={num}
                            className={`paginationBtn ${pageIndex === num ? "currPage" : ""}`}
                        >
                            {num}
                        </button>
                    ))}
                    {shouldShowRightDots && DOTS}
                    {controlledPageCount !== 1 && (
                        <button
                            onClick={() => gotoPage(controlledPageCount)}
                            key={controlledPageCount}
                            className={`paginationBtn ${pageIndex === controlledPageCount ? "currPage" : ""}`}
                        >
                            {controlledPageCount}
                        </button>
                    )}
                    <button onClick={() => gotoPage(pageCount)} disabled={!canNextPage} className='paginationBtn'>
                        Last
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage} className='paginationBtn'>
                        <HiOutlineArrowRight />
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default BootstrapTable