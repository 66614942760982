import React, { useCallback, useMemo } from 'react'
import { useState, useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formStatus, formTypes } from '../../assets/helper'
import { InputComponent } from '../../components'
import { setContactInfo, updateFormStatus } from '../../redux/FormSlice'
import './style.css'

const ContactInfo = () => {
    const navigate = useNavigate()

    const data = useSelector(state => state.form.contactInfo)
    const dispatch = useDispatch()
    const [errormessage, setErrormessage] = useState("")
    const [errormessageshow, setErrormessageshow] = useState(false)

    console.log(data)
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", 
        });
    }, []);

    const handleChange = useCallback((e) => {
        setErrormessageshow(false)
        const { name, value } = e.target;
        dispatch(setContactInfo([name, value]))
    }, [dispatch])

    const handleDataCenterChange = useCallback((e) => {
        setErrormessageshow(false)
        const { name } = e.target;
        dispatch(setContactInfo(['dataCenter', name]))
    }, [dispatch])
    const handleMailPlatformChange = useCallback(({ value }) => {
        setErrormessageshow(false)
        dispatch(setContactInfo(['mailPlatform', value]))
    }, [dispatch])

    const handleTechInfoNextStep = useCallback(() => {
        if (data.name === "" || data.email === "" || data.dataCenter === "" || data.domain === "" || data.mailPlatform.length < 1) {
            setErrormessage("All fields are mandatory")
            setErrormessageshow(true)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        else {
            dispatch(updateFormStatus({ type: formTypes.TechnicalContactInformation, status: formStatus.done }));
            dispatch(updateFormStatus({ type: formTypes.FinalConfirmation, status: formStatus.current }));
            // navigate('/products/form/final-confirmation', { replace: true })
            navigate('/products/form/final-confirmation')
        }
    }, [dispatch, data])



    const dataCenters = useMemo(() => ['US', 'EU', 'AP', 'CA', 'UK'], [])

    return (<Container>
        {errormessageshow ? (<div className="Error-Message"> {errormessage}</div>) : <></>}
        <Row><PartnerContact data={data} handleChange={handleChange} /></Row>
        <Row><DataCenter onClick={handleDataCenterChange} value={data.dataCenter} dataCenters={dataCenters} /></Row>
        <Row><Domains onChange={handleChange} value={data.domain} /></Row>
        <Row><MailPlatform onChange={handleMailPlatformChange} value={data.mailPlatform} /></Row>
        <Row><LegacyDataImport onChange={handleChange} value={data.legacyDataImport} /></Row>
        <Row><EPVault /></Row>
        <Row>
            <Col md={12} className="d-flex justify-content-center align-item-center mt-3">
                <span className="back-btn" onClick={() => { navigate(-1) }}>Go back</span>
                <Button className="next-step-btn" onClick={handleTechInfoNextStep}>Next Step</Button>
            </Col>
        </Row>
    </Container>
    )
}

const PartnerContact = ({ data, handleChange }) => (<>
    <Row className='contact-info-title'><Col md={12} xl={12}>Partner / Customer Contact Information</Col></Row>
    <Row className='contact-info-sub-title'><Col md={12} xl={12}>This resource will receive the Administrator credentials for Enterprise Vault.cloud</Col></Row>
    <Row className='contact-info-sub-title'>
        <Col md={12} xl={6} lg={12}>
            <InputComponent label={"Primary technical contact name*"} placeholder="Primary technical contact name*" name="name" value={data.name} onChange={handleChange}
            /></Col>
        <Col md={12} xl={6} lg={12}>
            <InputComponent label={"Primary technical contact email address*"} placeholder="Primary technical contact email address*" name={"email"} value={data.email} onChange={handleChange} />
        </Col>
    </Row>
</>)

const DataCenter = ({ onClick, value, dataCenters }) => (<>
    <Row className='contact-info-title'><Col md={12} xl={12}>Data Center*</Col></Row>
    <Row className='contact-info-sub-title'><Col md={12} xl={12}>Location of Data Center products will be provisioned in:</Col></Row>
    {dataCenters.map(element => <Col className='contact-info-sub-title' key={element}>
        <Col md={1} xl={1} className='contact-info-radio'>
            <span><input type={'radio'} value={value} name={element} onClick={onClick} checked={value === element} /></span>
        </Col>
        <Col>{element}</Col>
    </Col>)}
</>)

const Domains = ({ onChange, value }) => (
    <><Row className='contact-info-title'><Col md={12} xl={12}>Domains*</Col></Row>
        <Row className='contact-info-sub-title'><Col md={12} xl={12}>List all of the domains to be provisioned.
            Note: At least one domain is required for provisioning (Additional domains can be added via the Administration console at any time)</Col></Row>
        <Row className='contact-info-sub-title'>
            <Col md={12} xl={6} lg={12}><InputComponent label={"Domain*"} placeholder="Domain*" onChange={onChange} value={value} name="domain" /></Col>
        </Row></>
)

const MailPlatform = ({ onChange, value }) => {
    const handleChange = (event) => {
        const checked = event.target.checked;
        const data = event.target.value;
        if (checked) {
            onChange({ value: [...value, data] })
        }
        if (!checked) {
            onChange({ value: value.filter(e => e !== data) })
        }
    }
    return <><Row className='contact-info-title'><Col md={12} xl={12}>Mail Platforms*</Col></Row>
        <Row className='contact-info-sub-title'>
            <Col md={12} xl={12}>The platforms below are the only ones that are currently certified. Please indicate any that you use</Col>
        </Row>
        {/* <form onChange={onChange}> */}
        <Row className='contact-info-sub-title'>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Microsoft Exchange 2007" name='mailPlatform' checked={value.includes('Microsoft Exchange 2007')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Microsoft Exchange 2007</Col>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Domino 8.0 / 8.5" name='mailPlatform' checked={value.includes('Domino 8.0 / 8.5')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Domino 8.0 / 8.5</Col>
        </Row>
        <Row className='contact-info-sub-title'>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Microsoft Exchange 2010" name='mailPlatform' checked={value.includes('Microsoft Exchange 2010')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Microsoft Exchange 2010</Col>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Domino 9.0" name='mailPlatform' checked={value.includes('Domino 9.0')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Domino 9.0</Col>
        </Row>
        <Row className='contact-info-sub-title'>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Microsoft Exchange 2013" name='mailPlatform' checked={value.includes('Microsoft Exchange 2013')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Microsoft Exchange 2013</Col>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Domino 10" name='mailPlatform' checked={value.includes('Domino 10')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Domino 10</Col>
        </Row>
        <Row className='contact-info-sub-title'>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Microsoft Exchange 2016" name='mailPlatform' checked={value.includes('Microsoft Exchange 2016')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Microsoft Exchange 2016</Col>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Domino 11" name='mailPlatform' checked={value.includes('Domino 11')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Domino 11</Col>
        </Row>
        <Row className='contact-info-sub-title'>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Microsoft Exchange 2019" name='mailPlatform' checked={value.includes('Microsoft Exchange 2019')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Microsoft Exchange 2019</Col>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Domino 12" name='mailPlatform' checked={value.includes('Domino 12')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Domino 12</Col>
        </Row>
        <Row className='contact-info-sub-title'>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Microsoft Office 365" name='mailPlatform' checked={value.includes('Microsoft Office 365')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Microsoft Office 365</Col>
            <Col md={1} xl={1} className='contact-info-radio'>
                <span><input type={'checkbox'} value="Google G-Suite Gmail (Google G-Suite Enterprise)" name='mailPlatform' checked={value.includes('Google G-Suite Gmail (Google G-Suite Enterprise)')} onChange={handleChange} /></span>
            </Col>
            <Col md={5} xl={5}>Google G-Suite Gmail (Google G-Suite Enterprise)</Col>
        </Row>
        {/* </form> */}
    </>
}

const LegacyDataImport = ({ onChange, value }) => (
    <><Row className='contact-info-title'><Col md={12} xl={12}>Legacy Data Import</Col></Row>
        <Row className='contact-info-sub-title'>
            <Col md={12} xl={12}>In addition to capturing email on an ongoing basis through the journaling process, Veritas Enterprise Vault.cloud has the ability to migrate older messages or "legacy data" into your archive. Once data is migrated into the system, it is available in all applicable interfaces, including the eDiscovery view for your IT and legal admin as well as the Personal Archive for end users.
                <br />Note: Please complete the below section only if you have purchased legacy data ingestion services</Col>
        </Row>
        <Row className='contact-info-sub-title'>
            <Col md={12} xl={6} lg={12}>
                <InputComponent label={"Total Amount of Data to be imported (GB)"} placeholder="Total Amount of Data to be imported (GB)" onChange={onChange} value={value} name='legacyDataImport' />
            </Col>
        </Row></>
)

const EPVault = () => (
    <><Row className='contact-info-title'><Col md={12} xl={12}>Enterprise Vault to Enterprise Vault.cloud Cross Grade</Col></Row>
        <Row className='contact-info-sub-title'><Col md={12} xl={12}>This applies only if you have purchased cross-grade SKUs to an Enterprise Vault.cloud service.
            ICustomer acknowledges that the cross-grade from Enterprise Vault into Enterprise Vault.cloud will retire the applicable Enterprise Vault licenses and replace them with Enterprise Vault.cloud cross-grade licenses. For more information, please see the EV.cloud Service Description.
        </Col></Row></>
)

export default ContactInfo