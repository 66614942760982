/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getProductDetails } from '../../api'
import { formStatus, formType, formTypes, isLoggedIn } from '../../assets/helper'
import { ppg1, ppg2 } from '../../assets/images'
import { ContactUs } from '../../components'
import { resetFormStatus, setFormType, updateFormStatus } from '../../redux/FormSlice'
import './style.css'
import Swal from 'sweetalert2'


const Products = () => {
    const dispatch = useDispatch()
    const loggedIn = isLoggedIn()
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({ name: "", description: "" })

    useEffect(() => {
        if (!loggedIn) navigate('/login')
    }, [loggedIn])

    // const { error, isLoading, data } = useQuery('getProductDetails', { queryFn: () => getProductDetails({ id }) })

    const getProductDetailsRequest = async () => {
        console.log("token", localStorage.getItem('token'))
        const res = await getProductDetails({ id })
        setData(res?.data)
        setLoading(false)
    }

    useEffect(() => {
        isLoggedIn() && getProductDetailsRequest()
    }, [])
    useEffect(() => {
        dispatch(resetFormStatus())
    }, [])

    return (
        <div className='product-root'>
            {isLoading ? <><Spinner /></> : <div className="section-one">
                <div className="section-one-sub-one">
                    <p>{data?.name}</p>
                    <p id='tagline'>{data?.description}</p>
                    <div className='section-one-sub-one-btn'>
                        <button onClick={() => {
                            console.log("click")
                            dispatch(setFormType(formType.trial));
                            dispatch(updateFormStatus({ type: formTypes.TrialQuestionnaire, status: formStatus.current }));
                            navigate('/products/form/trial-questionnaire/' + data?.product_id, { replace: true })
                        }}>Begin Trial</button>
                        <button onClick={() => {
                            dispatch(setFormType(formType.nonTrial));
                            dispatch(updateFormStatus({ type: formTypes.ServicesSelection, status: formStatus.current }));
                            navigate('/products/form/select-sku/' + data?.product_id, { replace: true })
                        }}>More Products</button>
                    </div>
                </div>
                <div className="section-one-sub-two">
                    <img src={ppg1} alt='ppg1 ' />
                </div>
            </div>}
            <div className="section-two">
                <div className="section-two-sub-one">
                    <img src={ppg2} alt='ppg2 ' />
                    <div className='section-two-sub-one-content'>
                        <p className='mb-0'>Enhance data governance <br />across every platform.</p>
                    </div>
                </div>
                <div className="section-two-sub-two">
                    Enterprise Vault.cloud helps highly regulated organizations archive data across all communication platforms for seamless migration and automated identification of key content. For compliance officers and corporate legal IT, Enterprise Vault.cloud is the proven solution to meet your supervision and discovery requirements.
                </div>
            </div>
            <div className="section-three">
                <ContactUs />
            </div>
        </div>
    )
}

export default Products