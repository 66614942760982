import React, { useCallback } from 'react'
import './style.css'
import { NavbarBrand, Navbar } from 'react-bootstrap'
import { Logo } from '../../assets/images'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../../redux/UserSlice'
import { Auth } from 'aws-amplify'
import { isLoggedIn } from '../../assets/helper'


const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loggedIn = isLoggedIn()
    const scrollTo = useCallback(() => {
        const element = document.querySelector('#product-section')
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [])

    return (
        <div className='header'>
            <NavbarBrand className='logo' onClick={() => navigate('/', { replace: true })}>
                <img src={Logo} alt="logo" />
            </NavbarBrand>
            <div className='nav-links'>
                { loggedIn ? <Navbar.Text className='nav-product' onClick={() => navigate('/orders')}>Orders</Navbar.Text> : <></>}
                <Navbar.Text className='nav-product' onClick={() => scrollTo()}>Products</Navbar.Text>
                <Navbar.Text className='nav-login'>{!loggedIn ? <Button text={"Log In"} onClick={() => navigate('/login', { replace: true })} /> : <Button text={"Log Out"} onClick={async () => {
                    await Auth.signOut()
                    dispatch(logout());
                    navigate('/', { replace: true })
                }} />}</Navbar.Text>
            </div>
        </div>
    )
}

export default Header