import React, { useCallback, useMemo, useState } from 'react'
import './style.css'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { countryList } from '../../assets/constants'
import Button from '../Button'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { Auth } from 'aws-amplify'
import Swal from 'sweetalert2'
import { v4 } from 'uuid'

const RegistrationCard = () => {
    const navigate = useNavigate()
    const INITIAL_FORM = useMemo(() => ({ companyName: '', name: '', country: 'United States of America', email: "", password: "" }), [])
    const [form, setForm] = useState(INITIAL_FORM)
    const [showPass, setShowPass] = useState(false)
    const [userCreated, setUserCreated] = useState(false)
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [signuperror, setSignuperror] = useState("")
    const [showsignuperror, setShowsignuperror] = useState(false)

    const handleFormChange = useCallback(
        (e) => {
            setShowsignuperror(false)
            const { name, value } = e.target;
            setForm(state => ({ ...state, [`${name}`]: value }))
        },
        [],
    )
    const formSubmit = useCallback(async () => {
        setLoading(true)
        console.log(form)
        const { name, password, companyName, country, email, } = form
        try {
            if (form.companyName == "") {
                setShowsignuperror(true)
                setSignuperror("Company name cannot be empty")
                setLoading(false)
            }
            else if (form.name == "") {
                setShowsignuperror(true)
                setSignuperror("Contact name cannot be empty")
                setLoading(false)
            }
            else {
                const response = await Auth.signUp({
                    username: email,
                    password: password,
                    attributes: {
                        email,
                        name,
                        "custom:companyName": companyName,
                        "custom:country": country,
                        "custom:companyId": v4(),
                        "custom:accountId": v4()
                    }
                })
                console.log("response", response)
                setUserCreated(true)
                setLoading(false)
            }
        } catch (err) {
            console.error(err.message)
            if (err.message == "Password cannot be empty" || "An account with the given email already exists." || "Username cannot be empty") {
                setShowsignuperror(true)
                setSignuperror(err.message)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
            setLoading(false)
        }
    }, [form])

    const handleCodeSubmit = useCallback(async () => {
        setLoading(true)
        try {
            await Auth.confirmSignUp(form.email, code)
            Swal.fire({
                icon: 'success',
                title: 'User Created',
                showConfirmButton: false,
                timer: 1500
            })
            navigate('/', { replace: true });
        } catch (err) {
            console.error(err)
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }, [code, form.email, navigate])



    return (
        <div className='registration-card-container'>
            {!userCreated ? <>
                <div className="reg-title text-center">Create an Account</div>
                {showsignuperror ? (<div className="Error-Message"> {signuperror}</div>) : <></>}
                <div className="input-fields">
                    <Row>
                        <Col md={6} lg={6}>
                            <div className='management-form'>
                                <div className="form-control">
                                    <input type="text" className="form-input" placeholder="Company" name='companyName' value={form.companyName} onChange={handleFormChange} />
                                    <label className='form-label'>Company Name</label>
                                </div>

                                <div className="form-control">
                                    <input type="text" className="form-input" placeholder="ContactName" name="name" value={form.name} onChange={handleFormChange} />
                                    <label className='form-label'>Contact Name</label>
                                </div>
                                <div className="form-control">
                                    <select className="form-input" name="country" value={form.country || "United States of America"} onChange={handleFormChange}>
                                        <option disabled hidden>-- Please Select --</option>
                                        {countryList.map(country => <option key={country} value={country}>{country}</option>)}
                                    </select>
                                    <label className='form-label'>Location</label>
                                </div>
                                <div className="form-control">
                                    <input type="email" className="form-input" placeholder="Email" name='email' onChange={handleFormChange} value={form.email} />
                                    <label className='form-label'>Email</label>
                                </div>
                                <div className="form-control">
                                    <input type={showPass ? "text" : "password"} className="form-input" placeholder="Password" name='password' onChange={handleFormChange} value={form.password} />
                                    <label className='form-label'>Password</label>
                                    <div className='inputIcon' onClick={() => setShowPass(state => !state)}>
                                        {showPass ? <BsEyeSlash /> : <BsEye />}
                                    </div>
                                </div>
                                <Button text={loading ? <Spinner /> : "Create an Account"} onClick={formSubmit} />
                                <span className='login-text'>Already have an account? <span className='login-link' onClick={() => navigate('/login', { replace: true })}>Log In!</span></span>
                            </div>
                        </Col>
                        <Col md={6} lg={6}>
                            <div className='management-content'>
                                <div><strong>Veritas Technologies is a leader in multi-cloud data management. </strong></div>
                                <div>Over <span className='management-content-highlight'>80,000</span> customers—including <span className='management-content-highlight'>95%</span> of the Fortune 100.
                                </div>
                                <div>No other vendor is able to match our ability to execute, with support for <span className='management-content-highlight'>800+</span> data sources, <span className='management-content-highlight'>100+</span> operating systems, <span className='management-content-highlight'>1,400+ </span>storage targets, and <span className='management-content-highlight'>60+</span> clouds.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </> : <div className='input-fields'>
                <div className="management-form">
                    <div className="form-control">
                        <input type="text" className="form-input" placeholder="Company" name='companyName' value={code} onChange={(e) => setCode(e.target.value)} />
                        <label className='form-label'>Verification Code</label>
                    </div>
                    <Button text={loading ? <Spinner /> : "Submit"} onClick={handleCodeSubmit} />
                </div>
            </div>}
        </div>
    )
}

export default RegistrationCard