import React from 'react'
import './style.css'


const InputComponent = ({ type, placeholder, name, value, onChange, label }) => {
    return (
        <div className="input-component-form-control">
            <input type={type} className="input-component-form-input" placeholder={placeholder} name={name} value={value} onChange={onChange} />
            <label className='input-component-form-label'>{label}</label>
        </div>
    )
}

export default InputComponent