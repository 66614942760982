import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouterProvider } from 'react-router-dom'
import { router } from './router';
import { QueryClientProvider, QueryClient } from 'react-query'
import { Amplify } from 'aws-amplify';
import 'sweetalert2/src/sweetalert2.scss'

function App() {

  const queryClient = new QueryClient()
  Amplify.configure({
    region: "us-east-1",
    "userPoolId": "us-east-1_gr2SCJ6nv",
    "userPoolWebClientId": "51u6ltbd1h0fbrcujlcpq0mod9",
    "nativeClientId": "51u6ltbd1h0fbrcujlcpq0mod9",
  })

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
