import { createSlice } from '@reduxjs/toolkit';
import { formStatus, formTypes } from '../assets/helper';

const initialState = {
    formType: '',
    trialQuestions: {
        objective: "",
        timeline: "",
        total_user: "",
        country: "United States of America",
        address_1: "",
        address_2: "",
        state: "",
        city: "",
        postcode: "",
    },
    skuSelect: { selectedProduct: "", selectedSubProduct: "" },
    contactInfo: {
        name: "",
        email: "",
        dataCenter: "",
        domain: "",
        mailPlatform: [],
        legacyDataImport: "",
    },
    formStatus: {
        [`${formTypes.TrialQuestionnaire}`]: formStatus.pending,
        [`${formTypes.ServicesSelection}`]: formStatus.pending,
        [`${formTypes.TechnicalContactInformation}`]: formStatus.pending,
        [`${formTypes.FinalConfirmation}`]: formStatus.pending
    }
}

const initialFormStatus = {
    [`${formTypes.TrialQuestionnaire}`]: formStatus.pending,
    [`${formTypes.ServicesSelection}`]: formStatus.pending,
    [`${formTypes.TechnicalContactInformation}`]: formStatus.pending,
    [`${formTypes.FinalConfirmation}`]: formStatus.pending
}

const FormSlice = createSlice({
    initialState,
    name: 'form',
    reducers: {
        setFormType: (state, action) => {
            return { ...state, formType: action.payload }
        },
        setTrialQuestions: (state, action) => {
            const [key, value] = action.payload
            return { ...state, trialQuestions: { ...state.trialQuestions, [`${key}`]: value, } }
        },
        setSkuSelect: (state, action) => {
            const [key, value] = action.payload;
            return { ...state, skuSelect: { ...state.skuSelect, [`${key}`]: value, } }
        },
        setContactInfo: (state, action) => {
            const [key, value] = action.payload;
            return { ...state, contactInfo: { ...state.contactInfo, [`${key}`]: value, } }
        },
        setFinalConfirmation: (state, action) => {
            const [key, value] = action.payload;
            return { ...state, finalConfirmation: { ...state.finalConfirmation, [`${key}`]: value, } }
        },
        updateFormStatus: (state, { payload: { type, status } }) => {
            return { ...state, formStatus: { ...state.formStatus, [`${type}`]: status } }
        },
        resetFormStatus: (state) => {
            return { ...state, formStatus: initialFormStatus }
        },
        resetForm: (state) => {
            return { ...initialState }
        }
    }
})

export const { setFormType, setTrialQuestions, setSkuSelect, setContactInfo, setFinalConfirmation, updateFormStatus, resetFormStatus, resetForm } = FormSlice.actions
export default FormSlice.reducer
