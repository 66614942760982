
export const formType = {
    trial: "TRIAL",
    nonTrial: "REGULAR"
}

export const isLoggedIn = () => localStorage.getItem('token')

export const formStatus = {
    pending: "PENDING",
    current: "CURRENT",
    done: "DONE"
}

export const formTypes = {
    TrialQuestionnaire: 'Trial Questionnaire',
    ServicesSelection: 'Services Selection',
    TechnicalContactInformation: 'Technical Contact Information',
    FinalConfirmation: 'Final Confirmation'
}

export const FORM_STATUS = {
    submitted: "Submitted",
    quote_requested: "Quote Requested",
    awaiting_signature: "Awaiting Customer Sign Off",
    provisioning_in_progress: "Provisioning In Progress",
    provisioned: "Provisioned"
}