import { graphics } from '../../assets/images'
import React, { useCallback } from 'react'
import { Button } from '../../components'
import './style.css'
import { Col, Row } from 'react-bootstrap'

const FirstSection = () => {
    const scrollTo = useCallback(() => {
        const element = document.querySelector('#product-section')
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [])
    return (
        <div className='first-section'>
            <Row className='container'>
                <Col md='12' lg='6'>
                    <div className='catch-line'>
                        <div>We solve our customers' <br />biggest data management challenges.</div>
                        <Button text={"Explore Our Products"} onClick={scrollTo} />
                    </div>
                </Col>
                <Col md='12' lg='6'>
                    <div>
                        <img src={graphics} alt='homepage graphics' />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default FirstSection