import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formType } from '../../assets/helper'
import Status from '../Status'
import './style.css'

const FormCard = ({ children }) => {
    const navigate = useNavigate()
    const currFormType = useSelector(state => state.form.formType)
    const statuses = useMemo(() => {
        if (currFormType === formType.trial) return ['Trial Questionnaire', 'Services Selection', 'Technical Contact Information', 'Final Confirmation']
        if (currFormType === formType.nonTrial) return ['Services Selection', 'Technical Contact Information', 'Final Confirmation']
    }, [currFormType])
    useEffect(() => {
        if (!currFormType) return navigate('/', { replace: true })
    }, [currFormType, navigate])
    return (
        <div className='form-card-container'>
            <div className='form-card-status mb-3'>{statuses?.map((status, index) => <Status key={status} id={index + 1} text={status} current={index === 0} />)}</div>
            <div className='form-card-children mt-3'>{children}</div>
        </div>
    )
}

export default FormCard