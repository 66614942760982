import { apiConfig, secureApiConfig } from "./apiClient"
import Swal from 'sweetalert2'


export const getSkuOptions = async ({ productId = 1 }) => {

    try {
        const response = await secureApiConfig.get(`get-sku-options/${productId}`)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.error(err)
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message ?? 'Something went wrong!',
        })
    }
}
export const getProducts = async () => {

    try {
        const response = await apiConfig.get(`get-products`)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.error(err)
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message ?? 'Something went wrong!',
        })
    }
}
export const getProductDetails = async ({ id }) => {

    try {
        const response = await secureApiConfig.get(`get-product/${id}`)
        console.log("response", response)
        return response?.data
    } catch (err) {
        console.error(err)
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message ?? 'Something went wrong!',
        })
    }
}
export const getOrderList = async () => {

    try {
        const response = await secureApiConfig.get(`get-order-list`)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.error(err)
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message ?? 'Something went wrong!',
        })
    }
}
export const getOrderDetails = async ({ id }) => {

    try {
        const response = await secureApiConfig.get(`get-order-details/${id}`)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.log(err)
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message ?? 'Something went wrong!',
        })
    }
}
export const postOrder = async (data) => {

    try {
        const response = await secureApiConfig.post(`/order/post`, data)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.error(err)
        let errorMsg;
        if (err.response.data.message) {
            errorMsg = err.response.data.message
        } else {
            errorMsg = err.message ?? 'Something went wrong!'
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMsg,
        })
    }
}
export const updateOrder = async (id, data) => {

    try {
        const response = await secureApiConfig.put(`/order/update/` + id, data)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.error(err)
        let errorMsg;
        if (err.response.data.message) {
            errorMsg = err.response.data.message
        } else {
            errorMsg = err.message ?? 'Something went wrong!'
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMsg,
        })
    }
}
export const sendEmail = async (data) => {

    try {
        const response = await secureApiConfig.post(`/send-email`, data)
        console.log("response", response)
        return response.data
    } catch (err) {
        console.error(err)
        let errorMsg;
        if (err.response.data.message) {
            errorMsg = err.response.data.message
        } else {
            errorMsg = err.message ?? 'Something went wrong!'
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMsg,
        })
    }
}