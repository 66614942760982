import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isLoggedIn } from '../../assets/helper'
import { rpg1, rpg2 } from '../../assets/images'
import { LoginCard } from '../../components'
import './style.css'
const Login = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    window.onresize = () => setWindowWidth(window.innerWidth)
    const navigate = useNavigate()
    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/', { replace: true })
        }
    }, [navigate])
    return (
        <div className='login'>
            <div className="rpg1">
                <img src={rpg1} alt="rpg" width={(windowWidth * 90) / 100} />
            </div>
            <div className="rpg2">
                <img src={rpg2} alt="rpg" />
            </div>
            <div className='reg-card'>
                <LoginCard />
            </div>
        </div>
    )
}

export default Login