import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react'
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getOrderDetails, sendEmail, updateOrder } from '../../api'
import Swal from 'sweetalert2'
import './style.css'
import { FaPen } from 'react-icons/fa'
import { ImCross, ImCheckmark } from 'react-icons/im'
import { countryList } from '../../assets/constants'
import { useSelector } from 'react-redux'
import { check, file } from '../../assets/images';
import { InputComponent } from '../../components'
import { FORM_STATUS } from '../../assets/helper'

const QuoteConfirmation = ({ id, fetch }) => {
    const userGroup = useSelector(state => { console.log(state?.user?.userData?.groups); return state?.user?.userData?.groups ?? [] })
    const isCustomer = userGroup.includes('Customer')
    const isVeritas = userGroup.includes('Veritas')
    const { error, isLoading, data, refetch } = useQuery('getQuoteConfirmationData', { queryFn: () => getOrderDetails({ id }) })
    const initialFormDetailsState = {
        billTo: {
            name: "",
            company: "",
            country: "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip: " ",
            email: ""
        },
        soldTo: {
            name: "",
            company: "",
            country: "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip: " ",
            email: ""
        },
        shipTo: {
            name: "",
            company: "",
            country: "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip: " ",
            email: ""
        },
        endUser: {
            name: "",
            company: "",
            country: "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip: " ",
            email: ""
        },
        orderInfo: {
            quoteNumber: "",
            creationDate: "",
            quoteValidation: "",
            dealId: ""
        },
        accountRepresentative: {
            name: "",
            company: "",
            address: "",
            telephone: "",
            email: ""
        },
        paymentDetails: {
            paymentTerms: "",
            currency: "",
            taxExempt: "",
            coTerm: "",
            licenseCompliance: ""
        },
        productDetails: {
            qty: "",
            SKU: '',
            description: "",
            notes: "",
            productType: "",
            lastPrice: "",
            buyPrice: "",
            unitSellPrice: "",
            extendedPrice: "",
        }
    }
    const [initialFormData, setInitialFormData] = useState()
    const [orderDetails, setOrderDetails] = useState(initialFormDetailsState)
    const [show, setShow] = useState(false)
    const [quoteBtnLoading, setQuoteBtnLoading] = useState(false)
    const [provisionBtnLoading, setProvisionBtnLoading] = useState(false)
    const [docBtnLoading, setDocLoading] = useState(false)
    const onCancelClick = useCallback(
        () => {
            setOrderDetails(initialFormData)
        },
        [initialFormData],
    )

    const onUpdateClick = useCallback(async (data) => {
        const res = await updateOrder(id, { key: data, data: orderDetails[data] })
        console.log("res", res)
        await refetch()
    }, [id, orderDetails, refetch])

    useEffect(() => {
        if (error) Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
        })
    }, [error])
    console.log("orderDetails", orderDetails)
    useEffect(() => {
        if (data) { setOrderDetails(data.data); setInitialFormData(data.data) }
    }, [data])
    const handleChange = useCallback(
        ({ key, name, value }) => {
            console.log("handleChange", key, name, value)
            setOrderDetails((state => {
                const currentKeyValue = state[key]
                return { ...state, [`${key}`]: { ...currentKeyValue, [`${name}`]: value } }
            }))
        },
        [],
    )
    const generateQuoteClicked = useCallback(async () => {
        setQuoteBtnLoading(true)
        try {
            const response = await sendEmail({ "action" : "Quote" });
            const res = await updateOrder(id, { key: 'status', data: FORM_STATUS.quote_requested })
            console.log("res", res)
            Swal.fire({
                icon: 'success',
                title: 'Quote Generated Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            await fetch()
            // if (response) {
                
            // }

            setQuoteBtnLoading(false)
        } catch (err) {
            setQuoteBtnLoading(false)
        }
    }, [])

    const provisionClicked = useCallback(async () => {
        setProvisionBtnLoading(true)
        try {
            const response = await sendEmail({ "action" : "Provision" });
            const res = await updateOrder(id, { key: 'status', data: FORM_STATUS.provisioned })
            console.log("res", res)
            Swal.fire({
                icon: 'success',
                title: 'Provision Completed Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            await fetch()
            // if (response) {
                
            // }
            
            setProvisionBtnLoading(false)
        } catch (err) {
            setProvisionBtnLoading(false)
        }
    }, [])

    if (isLoading) return <Spinner />
    if (!data) return <Spinner />

    return (
        <Container className='quote-conf-container'>
            <SignDocModal show={show} setShow={setShow} />
            <Row>
                {/* Bill To */}
                <Col md={3} xl={3} className="section-container">
                    <UserInfo heading="Bill To" data={orderDetails.billTo} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'billTo'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
                {/* Bill Sold */}
                <Col md={3} xl={3} className="section-container">
                    <UserInfo heading="Sold To" data={orderDetails.soldTo} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'soldTo'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
                {/* Quote details */}
                <Col md={{ offset: 1, span: 5 }} xl={{ offset: 1, span: 5 }} className="section-container quote-details">
                    <QuoteDetails data={orderDetails.orderInfo} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'orderInfo'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
            </Row>
            <Row>
                {/* Ship to */}
                <Col md={4} xl={4} className="section-container">
                    <UserInfo heading="Ship To" data={orderDetails.shipTo} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'shipTo'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
                {/* End User */}
                <Col md={4} xl={4} className="section-container">
                    <UserInfo heading="End User" data={orderDetails.endUser} editable={(isCustomer || isVeritas) ? true : false} onChange={handleChange} objectKey={'endUser'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
            </Row>
            <Row>
                {/* Representative */}
                <Col md={6} xl={6} className="section-container">
                    <Representative data={orderDetails.accountRepresentative} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'accountRepresentative'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
                {/* Payment details */}
                <Col md={6} xl={6} className="section-container">
                    <PaymentDetails data={orderDetails.paymentDetails} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'paymentDetails'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
                </Col>
            </Row>
            <Row>
                <ProductDetails data={orderDetails.productDetails ?? {}} editable={(isVeritas) ? true : false} onChange={handleChange} objectKey={'productDetails'} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />
            </Row>
            <Row>
                {/* tax info */}
                <Col md={12} xl={12} className="section-container">
                    <TaxInfo />
                </Col>
            </Row>
            <Row>
                <Col md={12} className="d-flex justify-content-around align-item-center mt-3">
                    {/* <span className="back-btn" onClick={() => navigate('/products/form/quote-confirmation', { replace: true })}>Go back</span> */}
                    {(isVeritas) && <Button className="next-step-btn" onClick={generateQuoteClicked}>{quoteBtnLoading ? <Spinner /> : "Generate Quote"}</Button>}
                    {(isVeritas) && <Button className="next-step-btn" onClick={provisionClicked}>{provisionBtnLoading ? <Spinner /> : "Provision"}</Button>}
                    {(isCustomer) && <Button className="next-step-btn" onClick={() => setShow(true)}>{docBtnLoading ? <Spinner /> : "Sign the document"}</Button>}
                </Col>
            </Row>
        </Container>
    )
}

const SignDocModal = ({ show, setShow }) => {
    const inputRef = useRef()
    const [signDoc, setSignDoc] = useState()
    const [showSuccess, setSuccess] = useState(false)
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Sign the Document</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!showSuccess ? <><div className='sign-doc-modal-body' onClick={(e) => e.target !== inputRef.current && inputRef.current.click()}>
                    <input type={'file'} className='sign-doc-input-btn' ref={inputRef} onChange={(e) => setSignDoc(e.target.files[0])} />
                    <div className='sign-doc-file-name'>
                        <span>{signDoc ? signDoc.name : 'Select file with an electronic signature'}</span>
                        <img className='file-icon' src={file} alt="file" />
                        {signDoc && <span className='change-file-btn'>{'Change File'}</span>}
                    </div>
                </div>
                    <div>
                        <p>Type your password</p>
                        <InputComponent label={"Password"} name="name" type={'password'} placeholder="Password" />
                        <Button className='pass-btn' onClick={() => { setSuccess(true) }}>Sign the Document</Button>
                    </div></> : <div className='success-msg'><img src={check} alt='success' /><div>Your document is signed</div></div>}
            </Modal.Body>
        </Modal>
    )
}

const UserInfo = ({ heading, data: { name, company, country, address1, address2, state, city, zip, email }, editable, onChange, objectKey, onCancelClick, onUpdateClick }) => {
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const updateClick = async () => { setLoading(true); await onUpdateClick(objectKey); setLoading(false); setEdit(state => !state) }
    const cancelClick = () => { setEdit(state => !state); onCancelClick() }
    return <>
        <div className="quote-confirm-title">{heading}{editable && editBtn({ edit, loading, onClick: () => { setEdit(state => !state) }, onCancelClick: cancelClick, onUpdateClick: updateClick })}</div>
        {edit ? <><span>Name</span><input value={name} name="name" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></> : <div className="quote-confirm-name">{name}</div>}
        <div className="quote-confirm-address">
            {edit ? <><span>Company Name</span><input value={company} name="company" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></> : <div>{company}</div>}
            {edit ? <><span>Address1</span><input value={address1} name="address1" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </> : <div>{address1}</div>}
            {edit ? <><span>Address 2</span><input value={address2} name="address2" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </> : <div>{address2}</div>}
            {edit ? <><span>City</span><input value={city} name="city" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></> : <div>{city}</div>}
            {edit ? <><span>State</span><input value={state} name="state" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></> : <div>{state}</div>}
            {edit ? <><span>Zip</span><input value={zip} name="zip" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></> : <div>{zip}</div>}
            {edit ? <><span>Country</span><select value={country} name="country" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} >{countryList.map(country => <option key={country} value={country}>{country}</option>)}</select></> : <div>{country}</div>}
        </div>
        {edit ? <><span>Email</span> <input value={email} name="email" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></> : <div className="quote-confirm-email">{email}</div>}
    </>
}

const QuoteDetails = ({ data: { quoteNumber, creationDate, quoteValidation, dealId }, editable, onChange, objectKey, onCancelClick, onUpdateClick }) => {
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const updateClick = async () => { setLoading(true); await onUpdateClick(objectKey); setLoading(false); setEdit(state => !state) }
    const cancelClick = () => { setEdit(state => !state); onCancelClick() }

    return <>
        <div className="quote-confirm-title" style={{ justifyContent: "center" }}><div>Quote Info</div>{editable && editBtn({ edit, loading, onClick: () => { setEdit(state => !state) }, onCancelClick: cancelClick, onUpdateClick: updateClick })}</div>
        <Row className='row-style'><Col md={6} xl={6}>Quote Number:</Col>{edit ? <Col className='quote-details-value'><input value={quoteNumber} name="quoteNumber" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> : <Col className='quote-details-value'>{quoteNumber}</Col>}</Row>
        <Row className='row-style'><Col md={6} xl={6}>Creation Date:</Col>{edit ? <Col className='quote-details-value'><input value={creationDate} name="creationDate" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> : <Col className='quote-details-value'>{creationDate}</Col>}</Row>
        <Row className='row-style'><Col md={6} xl={6}>This Quote is valid until:</Col>{edit ? <Col className='quote-details-value'><input value={quoteValidation} name="quoteValidation" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> : <Col className='quote-details-value'>{quoteValidation}</Col>}</Row>
        <Row className='row-style'><Col md={6} xl={6}>Deal ID:</Col>{edit ? <Col className='quote-details-value'><input value={dealId} name="dealId" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> : <Col className='quote-details-value'>{dealId}</Col>}</Row>
    </>
}

const Representative = ({ data: { name, company, address, telephone, email }, editable, onChange, objectKey, onCancelClick, onUpdateClick }) => {
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const updateClick = async () => { setLoading(true); await onUpdateClick(objectKey); setLoading(false); setEdit(state => !state) }
    const cancelClick = () => { setEdit(state => !state); onCancelClick() }

    return <>
        <Row className='row-style'><Col md={6} xl={6} className='representative-heading'>Account Representative{editable && editBtn({ edit, loading, onClick: () => { setEdit(state => !state) }, onCancelClick: cancelClick, onUpdateClick: updateClick })}</Col></Row>
        <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Name:</Col>
            {edit ? <Col className='quote-details-value'><input value={name} name="name" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{name}</Col>}
        </Row>
        <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Company:</Col>
            {edit ? <Col className='quote-details-value'><input value={company} name="company" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{company}</Col>}
        </Row>
        <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Address:</Col>
            {edit ? <Col className='quote-details-value'><input value={address} name="address" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{address}</Col>}
        </Row>
        <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Telephone:</Col>
            {edit ? <Col className='quote-details-value'><input value={telephone} name="telephone" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{telephone}</Col>}
        </Row>
        <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Email:</Col>
            {edit ? <Col className='quote-details-value'><input value={email} name="email" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{email}</Col>}
        </Row>
    </>
}

const PaymentDetails = ({ data: { paymentTerms, currency, taxExempt, coTerm, licenseCompliance }, editable, onChange, objectKey, onCancelClick, onUpdateClick }) => {
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const updateClick = async () => { setLoading(true); await onUpdateClick(objectKey); setLoading(false); setEdit(state => !state) }
    const cancelClick = () => { setEdit(state => !state); onCancelClick() }
    return <>
        <Row className='row-style'><Col md={6} xl={6} className='representative-heading'>Payment Details{editable && editBtn({ edit, loading, onClick: () => { setEdit(state => !state) }, onCancelClick: cancelClick, onUpdateClick: updateClick })}</Col></Row>
        <Row className='row-style'><Col md={5} xl={5} className='representative-key'>Payment Terms:</Col>
            {edit ? <Col className='quote-details-value'><input value={paymentTerms} name="paymentTerms" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{paymentTerms}</Col>}
        </Row>
        <Row className='row-style'><Col md={5} xl={5} className='representative-key'>Currency:</Col>
            {edit ? <Col className='quote-details-value'><input value={currency} name="currency" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{currency}</Col>}
        </Row>
        <Row className='row-style'><Col md={5} xl={5} className='representative-key'>Tax Exempt:</Col>
            {edit ? <Col className='quote-details-value'><input value={taxExempt} name="taxExempt" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{taxExempt}</Col>}
        </Row>
        <Row className='row-style'><Col md={5} xl={5} className='representative-key'>Co-Term:</Col>
            {edit ? <Col className='quote-details-value'><input value={coTerm} name="coTerm" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> :
                <Col className='quote-details-value'>{coTerm}</Col>}
        </Row>
        <Row className='row-style'><Col md={5} xl={5} className='representative-key'>License Compliance:</Col>
            {edit ? <Col className='quote-details-value'> <input value={licenseCompliance} name="licenseCompliance" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> : <Col className='quote-details-value'>{licenseCompliance}</Col>}
        </Row>

    </>
}

const ProductDetails = ({ data: { qty = "", SKU = "", description = "", productType = "", lastPrice = "", buyPrice = "", unitSellPrice = "", extendedPrice = "" }, editable, onChange, objectKey, onCancelClick, onUpdateClick }) => {
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const updateClick = async () => { setLoading(true); await onUpdateClick(objectKey); setLoading(false); setEdit(state => !state) }
    const cancelClick = () => { setEdit(state => !state); onCancelClick() }

    return <>
        <Col md={8} xl={8} className="section-container">
            <Col>
                <Row className='row-style'><Col md={6} xl={6} className='representative-heading'>Product Details {editable && editBtn({ edit, loading, onClick: () => { setEdit(state => !state) }, onCancelClick: cancelClick, onUpdateClick: updateClick })}</Col></Row>
                <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Qty:</Col>
                    {/* {edit ? <Col className='quote-details-value'><input value={qty} name="qty" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> : */}
                    <Col className='quote-details-value'>{qty}</Col>
                    {/* } */}
                </Row>
                <Row className='row-style'><Col md={3} xl={3} className='representative-key'>SKU #:</Col>
                    {/* {edit ? <Col className='quote-details-value'><input value={SKU} name="SKU" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> : */}
                    <Col className='quote-details-value'>{SKU}</Col>
                    {/* } */}
                </Row>
                <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Description:</Col>
                    {/* {edit ? <Col className='quote-details-value'><input value={description} name="description" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /></Col> : */}
                    <Col className='quote-details-value'>{description}</Col>
                    {/* } */}
                </Row>
                <Row className='row-style'><Col md={3} xl={3} className='representative-key'>Buy Price:</Col>
                    {edit ? <Col className='quote-details-value'><input value={buyPrice} name="buyPrice" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> :
                        <Col className='quote-details-value'>{buyPrice}</Col>}
                </Row>

            </Col>
        </Col>
        <Col md={4} xl={4} className="section-container product-price">
            <Row className='row-style'><Col md={6} xl={6} className='representative-heading'></Col></Row>
            {/* <Row className='row-style'><Col md={6} xl={6} className='representative-key'>Product Type:</Col>
                {edit ? <Col className='quote-details-value'><input value={productType} name="productType" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> :
                    <Col className='quote-details-value'>{productType}</Col>}
            </Row>
            <Row className='row-style'><Col md={6} xl={6} className='representative-key'>Last Price:</Col>
                {edit ? <Col className='quote-details-value'><input value={lastPrice} name="lastPrice" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> :
                    <Col className='quote-details-value'>{lastPrice}</Col>}
            </Row> */}
            {/* <Row className='row-style'><Col md={6} xl={6} className='representative-key'>Buy Price:</Col>
                {edit ? <Col className='quote-details-value'><input value={buyPrice} name="buyPrice" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> :
                    <Col className='quote-details-value'>{buyPrice}</Col>}
            </Row> */}
            {/* <Row className='row-style'><Col md={6} xl={6} className='representative-key'>Unit Sell Price:</Col>
                {edit ? <Col className='quote-details-value'><input value={unitSellPrice} name="unitSellPrice" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> :
                    <Col className='quote-details-value'>{unitSellPrice}</Col>}
            </Row>
            <Row className='row-style'><Col md={6} xl={6} className='representative-key'>Extended Price:</Col>
                {edit ? <Col className='quote-details-value'><input value={extendedPrice} name="extendedPrice" onChange={(e) => { const { name, value } = e.target; onChange({ key: objectKey, name, value }) }} /> </Col> :
                    <Col className='quote-details-value'>{extendedPrice}</Col>}
            </Row> */}
        </Col>
    </>
}

const TaxInfo = () => (<><div className="representative-heading">Tax Information</div>
    <div className="tax-info-text">Tax information for orders placed in the United States and Canada only: All orders are subject to sakes tax. If you are requesting a tax exemption, please provide a copy of your sales tax exemption certificate with Your order.</div></>)

const editBtn = ({ edit, loading, onClick, onUpdateClick, onCancelClick }) => {
    if (loading) return <span style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}> <Spinner /> </span>
    return <span className='order-edit-btn' >{edit ? <span style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}> <ImCheckmark onClick={onUpdateClick} /> <ImCross className='pl-5' onClick={onCancelClick} /></span> : loading ? <Spinner /> : <FaPen onClick={onClick} />}</span>
}


export default QuoteConfirmation