import React, { useState } from 'react'
import { rpg1, rpg2 } from '../../assets/images'
import { ForgotPasswordCard } from '../../components'

import './style.css'
const ForgotPassword = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    window.onresize = () => setWindowWidth(window.innerWidth)
    return (
        <div className='forgotpassword'>
            <div className="rpg1">
                <img src={rpg1} alt="rpg" width={(windowWidth * 90) / 100} />
            </div>
            <div className="rpg2">
                <img src={rpg2} alt="rpg" />
            </div>
            <div className='pass-card'>
                <ForgotPasswordCard />
            </div>
        </div>
    )
}

export default ForgotPassword