import React from 'react'
import './style.css'
import { Outlet } from 'react-router-dom'

const Secure = () => {
    return (
        <Outlet />
    )
}

export default Secure