import React from 'react'
import './style.css'
import { Footer, Header } from '../../components'
import { Outlet } from 'react-router-dom'

const Layout = () => {
    return (
        <div className='layout'>
            <Header />
            <div><Outlet /></div>
            <Footer />
        </div>
    )
}

export default Layout