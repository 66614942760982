import { createSlice } from '@reduxjs/toolkit'

const initialState = { skuList: [], selectedSku: { sku: "", option: "" } }
export const skuSlice = createSlice({
    name: 'sku',
    initialState,
    reducers: {
        setSkuList: (state, action) => {
            return { ...state, skuList: action.payload }
        },
        clearSku: async (state) => {
            return { ...state, ...initialState }
        },
        setSelectedSku: (state, action) => {
            let selectedSku = {}
            const sku = action.payload.sku
            const option = action.payload.option ?? ''
            selectedSku['sku'] = state.skuList.filter(e => e.sku === sku)[0]
            if (option !== '') selectedSku['option'] = selectedSku['sku'].options.filter(e => e.id === option)[0]
            return { ...state, selectedSku }
        },
    }
})

export const { setSkuList, clearSku, setSelectedSku } = skuSlice.actions

export default skuSlice.reducer