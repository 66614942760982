import axios from 'axios';

// const options = 

const api = () => {
    const config = axios.create({
        baseURL: 'https://w5l35f0qq3.execute-api.us-east-1.amazonaws.com/dev',
        headers: {
            "Content-Type": "application/json",
            // "x-api-key": "SGGIFE4AYR2v6vpFa04Ny45l68Isjpb21bBYTmH8",
            "Access-Control-Allow-Origin": "*",
            // "Authorization": "Bearer " + store.getState().user?.userData?.token
        }
    })

    return config
}
const secureApi = () => {
    const config = axios.create({
        baseURL: 'https://w5l35f0qq3.execute-api.us-east-1.amazonaws.com/dev',
        headers: {
            "Content-Type": "application/json",
            // "x-api-key": "SGGIFE4AYR2v6vpFa04Ny45l68Isjpb21bBYTmH8",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })

    return config
}

const secureApiConfig = {
    get: async (path) => await secureApi().get(path),
    post: async (path, body) => await secureApi().post(path, body),
    put: async (path, body) => await secureApi().put(path, body),
}
const apiConfig = {
    get: async (path) => await api().get(path),
    post: async (path, body) => await api().post(path, body),
    put: async (path, body) => await api().put(path, body),
}


export { apiConfig, secureApiConfig }