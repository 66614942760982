import React, { useState } from 'react'
import { Accordion, Col, Row, useAccordionButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setSkuSelect } from '../../redux/FormSlice'
import './style.css'

const SelectSkuRow = ({ selected, service, description, meter, value, extras, setSkuerrorshow }) => {
    const [selectExtra, setSelectExtra] = useState()
    const selectedRow = useSelector(state => state.form.skuSelect)
    const dispatch = useDispatch()
    return (
        <Row style={{ background: selectedRow.selectedProduct === value ? '#F4F5F8' : '' }} className='sku-select-row-container'>
            <Col md={1} xl={1} className="sku-select-row-radio">
                <CustomToggle eventKey={value}>
                    <input type={'radio'} value={selectedRow.selectedProduct} name={value} id='state' onClick={(e) => { setSkuerrorshow(false); dispatch(setSkuSelect(["selectedProduct", e.target.name])) }} checked={selectedRow.selectedProduct === value} />
                </CustomToggle>
            </Col>
            <Col md={3} xl={3} className="sku-select-row-service" style={{ color: selected ? '#B72127' : '#182C35' }}>{service}</Col>
            <Col md={6} xl={6} className="sku-select-row-description" style={{ color: selected ? '#182C35' : '#6C757D' }}>{description}</Col>
            <Col md={2} xl={2} className="sku-select-row-meter" style={{ color: selected ? '#182C35' : '#6C757D' }}>{meter}</Col>
            <Accordion.Collapse eventKey={value}>
                {(extras) ?
                    <>
                        <Row className="justify-content-md-center"><Col md={{ span: 10, offset: 2 }} xl={{ span: 10, offset: 2 }} className='line'></Col></Row>
                        <form onChange={(e) => setSelectExtra(e.target.value)}>
                            <Row className='sku-select-row-extra'>
                                <Col md={12} xl={12} className="sku-select-row-extras-title">{"Two Storage & Retention Options:"}</Col>
                                {extras.map(option => <Row key={option.id}>
                                    <Col md={1} xl={1} className="sku-select-row-radio">
                                        <input type={'radio'} value={selectedRow.selectedSubProduct} name={option.id} onClick={(e) => { setSkuerrorshow(false); dispatch(setSkuSelect(["selectedSubProduct", e.target.name])) }} checked={selectedRow.selectedSubProduct === option.id} />
                                    </Col>
                                    <Col md={11} xl={11} className="sku-select-row-option" style={{ color: selectExtra === option.text ? '#B72127' : '#182C35' }}>{option.text}</Col>
                                </Row>)}
                            </Row>
                        </form>
                    </> : <></>
                }
            </Accordion.Collapse>
        </Row>
    )
}

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => { });

    return (
        <span
            onClick={decoratedOnClick}
        >
            {children}
        </span>
    );
}

export default SelectSkuRow