import React from 'react'
import './style.css'
import { useSelector } from 'react-redux'
import { formStatus } from '../../assets/helper'
import { tick } from '../../assets/images'

const Status = ({ completed, text, id, current }) => {
    const { formStatus: status } = useSelector(state => state.form)
    const currStatus = status[text]
    return (
        <div className='status-container'>
            <div className={`status-container-number${currStatus === formStatus.current ? '-current' : currStatus === formStatus.done ? '-completed' : '-not-done'}`}><span style={{ display: 'flex' }}>{currStatus === formStatus.done ? <img src={tick} alt="tick" /> : id}</span></div>
            <div className='status-container-text'>{text}</div>
        </div>
    )
}

export default Status